import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layouts/Layout'
import { notFoundPage, goBackLink, seperator, notFoundTitle, notFoundWrapper } from './404.module.scss'

const PageNotFound = () => {
  return (
    <Layout footerVisible={false} className={notFoundPage}>
      <Helmet>
        <title>Not Found - Emso</title>
      </Helmet>
      <section className={notFoundWrapper}>
        <h1 className={notFoundTitle}>PAGE NOT FOUND</h1>
        <div className={seperator} />
        <h2 className={goBackLink}>
          Go back{' '}
          <a href="/">
            home
          </a>
        </h2>
      </section>
    </Layout>
  )
}

export default PageNotFound
